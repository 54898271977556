import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import {
  ApiClient,
  Button,
  CheckAuthorization,
  CheckIfLoggedIn,
  Input
} from '../components';
import USERROLES from '../constants/UserRoles';
import { validateEmail } from '../helpers/validator';

export function EmailSettingEdit() {
  const navigate = useNavigate();
  let { settingId } = useParams();
  let { addToast } = useToasts();

  const [error, setError] = useState({
    emailaddress: '',
    host: '',
    password: '',
    port: ''
  });

  const [emailSetting, setEmailSetting] = useState({
    emailaddress: '',
    host: '',
    password: '',
    port: ''
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = 'AMP | Emaileinstellungen editieren';
    ApiClient.get(`email/settings/${settingId}`)
      .then(searchedSetting => setEmailSetting(searchedSetting))
      .catch(() =>
        addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
      );
  }, [addToast, settingId]);

  function onInputChange(field, value) {
    setEmailSetting({ ...emailSetting, [field]: value });
    setError({ ...error, [field]: '' });
  }

  const validateInputFields = emailSetting => {
    return (
      emailSetting.port &&
      emailSetting.host &&
      emailSetting.emailaddress &&
      emailSetting.password
    );
  };

  function handleSubmitClicked() {
    if (!validateInputFields(emailSetting)) {
      return setError({
        ...error,
        host: 'Bitte Host eingeben',
        emailaddress: 'Bitte Emailadresse eingeben'
      });
    }

    if (!validateEmail(emailSetting.emailaddress)) {
      return setError({
        ...error,
        emailaddress: 'Bitte eine gültige Mail eingeben'
      });
    }

    let patchObject = {
      host: emailSetting.host,
      port: emailSetting.port,
      emailaddress: emailSetting.emailaddress,
      password: emailSetting.password
    };
    setLoading(true);

    ApiClient.patch(`email/settings/${settingId}`, patchObject)
      .then(() => {
        addToast('Server Einstellungen erfolgreich bearbeitet', {
          appearance: 'success'
        });
        navigate('/email_settings');
      })
      .catch(() =>
        addToast('Etwas ist schief gelaufen', { appearance: 'error' })
      );
  }

  return (
    <CheckIfLoggedIn>
      <CheckAuthorization roles={[USERROLES.ADMIN]} shouldNavigate={false}>
        <div className='email-settings container'>
          <div className='content'>
            <h1 className='title'> Email Einstellungen bearbeiten</h1>
            <div className='email-settings-edit form'>
              <Input
                error={error.host}
                placeholder='Host'
                type='text'
                value={emailSetting.host}
                onChange={event => onInputChange('host', event.target.value)}
                required
              />
              <Input
                error={error.port}
                placeholder='Port'
                type='number'
                value={emailSetting.port}
                onChange={event => onInputChange('port', event.target.value)}
                required
              />
              <Input
                error={error.emailaddress}
                placeholder='Emailadresse'
                type='text'
                value={emailSetting.emailaddress}
                onChange={event =>
                  onInputChange('emailaddress', event.target.value)
                }
                required
              />
              <Input
                error={error.password}
                placeholder='Passwort'
                type='Password'
                value={emailSetting.password}
                onChange={event =>
                  onInputChange('password', event.target.value)
                }
              />
              <div className='button-group'>
                <Button
                  text='Speichern'
                  disabled={loading}
                  loading={loading}
                  className='hero'
                  onClick={handleSubmitClicked}
                />
                <Button
                  text='Abbrechen'
                  disabled={false}
                  loading={false}
                  className=''
                  onClick={() => navigate('/email_settings')}
                />
              </div>
            </div>
          </div>
        </div>
      </CheckAuthorization>
    </CheckIfLoggedIn>
  );
}
