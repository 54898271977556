//Cheyenne Sägesser
import { useEffect, useState } from 'react';
import { GoPencil } from 'react-icons/go';
import { MdOutlineMarkEmailRead } from 'react-icons/md';
import { SiMinutemailer } from 'react-icons/si';
import { useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import {
  ApiClient,
  CheckAuthorization,
  CheckIfLoggedIn,
  LoadingIndicator
} from '../components';
import UserRoles from '../constants/UserRoles';

export function EmailSettingOverview() {
  const [emailSettings, setEmailSettings] = useState([]);
  const [emailSettingsId, setEmailSettingsId] = useState();
  const [isSuccess, setIsSuccess] = useState(false);
  const navigate = useNavigate();
  const { addToast } = useToasts();

  useEffect(() => {
    ApiClient.get('/email/settings/')
      .then(settings => {
        setEmailSettings(settings);
      })
      .catch(() => {
        addToast('Etwas ist schiefgelaufen', { appearance: 'error' });
      });
  }, [addToast]);

  function iconOnClick() {
    ApiClient.post('email/settings/test')
      .then(() => {
        setIsSuccess(true);
        addToast('Email wurde versendet', { appearance: 'success' });
      })
      .catch(() => {
        setIsSuccess(false);
        addToast('Email konnte nicht versendet werden', {
          appearance: 'error'
        });
      });
  }
  return (
    <CheckIfLoggedIn>
      <CheckAuthorization roles={[UserRoles.ADMIN]} shouldNavigate path='/403'>
        <div className='emailSettings container'>
          <div className='content'>
            <h1 className='title'>
              Email Einstellungen
              <GoPencil
                onClick={() => navigate(`/emailSettings/${emailSettingsId}`)}
                className='title-icon'
              />
            </h1>
            <div className='email-settings'>
              {emailSettings ? (
                emailSettings.map(emailSetting => (
                  <>
                    {emailSettingsId !== emailSetting.id
                      ? setEmailSettingsId(emailSetting.id)
                      : ''}
                    <div className='email-setting-item'>
                      <h2>Server Einstellungen</h2>
                      <div className='email-setting'>
                        <p>Host: {emailSetting.host}</p>
                        <p>Port: {emailSetting.port}</p>
                        <p>Emailadresse: {emailSetting.emailaddress}</p>
                        <p>
                          Test Email versenden:{' '}
                          {isSuccess ? (
                            <MdOutlineMarkEmailRead />
                          ) : (
                            <SiMinutemailer onClick={() => iconOnClick()} />
                          )}
                        </p>
                      </div>
                    </div>
                  </>
                ))
              ) : (
                <LoadingIndicator height={25} width={25} mode='darker' />
              )}
            </div>
          </div>
        </div>
      </CheckAuthorization>
    </CheckIfLoggedIn>
  );
}
