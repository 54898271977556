import '../styles/ProfessionsOverview.css';

import { useEffect, useState } from 'react';
import { GrPlan } from 'react-icons/gr';
import { ImBooks } from 'react-icons/im';
import { IoIosAdd } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import {
  ApiClient,
  CheckIfLoggedIn,
  LoadingIndicator,
  Row,
  Table
} from '../components';

export function ProfessionsOverview({ setBreadcrumbLinks }) {
  const { addToast } = useToasts();
  const [professions, setSemesters] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'AMP | Berufe übersicht';
    setBreadcrumbLinks([
      { id: 1, label: 'Berufe anzeigen', path: '/professions' }
    ]);
    ApiClient.get('professions')
      .then(({ professions }) => setSemesters(professions))
      .catch(() =>
        addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
      );
  }, [addToast, setBreadcrumbLinks]);

  return (
    <CheckIfLoggedIn>
      <div className='professionsOverview container'>
        <div className='content'>
          <h1 className='title'>
            Alle Berufe
            <IoIosAdd
              onClick={() => navigate('/professions/create')}
              className='title-icon'
            />
          </h1>
          {professions ? (
            Array.isArray(professions) && professions.length > 0 ? (
              <Table leftAlign>
                {professions.map(profession => (
                  <Row
                    key={profession.id}
                    data={[
                      profession.name,
                      <ImBooks
                        key={profession.id}
                        onClick={() => navigate(`${profession.id}/subjects`)}
                        className='professionsOverview-table-icon'
                        title='Schulfächer des Berufs ändern'
                      />,
                      <GrPlan
                        key={'plan-icon'}
                        className='professionsOverview-table-icon'
                        title='Basisplan des Berufs ändern'
                        onClick={() => navigate(`/plan/${profession.plan_id}`)}
                      />
                    ]}
                  />
                ))}
              </Table>
            ) : (
              <h2>Keine Berufe vorhanden!</h2>
            )
          ) : (
            <LoadingIndicator height={25} width={25} mode='darker' />
          )}
        </div>
      </div>
    </CheckIfLoggedIn>
  );
}
