import '../styles/Header.css';

import { useEffect, useState } from 'react';
import { BsBoxArrowInLeft } from 'react-icons/bs';
import { IoIosArrowDown } from 'react-icons/io';
import { Link, useLocation, useMatch, useNavigate } from 'react-router-dom';

import USERROLES from '../constants/UserRoles';
import { getUserInfo } from '../helpers/auth';
import { CheckAuthorization, CheckIfLoggedIn } from '.';

export function Header({ isEditMode }) {
  const navigate = useNavigate();
  const parameters = useMatch('/users/:userId/semesters/:semesterId/*')?.params;
  const userParameters = useMatch('/users/:userId/*')?.params;
  let location = useLocation();

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const [isMobileHeaderOpen, setIsMobileHeaderOpen] = useState(false);
  let accessToken = localStorage.getItem('access_token');

  useEffect(() => setUserInfo(getUserInfo()), [accessToken]);

  return (
    <header className={isEditMode ? 'edit-header' : 'cyan-stripe'}>
      {isEditMode ? (
        <div
          onClick={() =>
            navigate(
              userParameters?.userId
                ? `/users/${userParameters?.userId}/semesters`
                : '/professions'
            )
          }
          className='edit-return'
        >
          <BsBoxArrowInLeft />
        </div>
      ) : (
        <input
          type='checkbox'
          disabled={location.pathname === '/login' ? true : false}
          checked={isMobileHeaderOpen}
          onChange={() => setIsMobileHeaderOpen(!isMobileHeaderOpen)}
        />
      )}
      <div className='content'>
        <h1 onClick={() => navigate('/')}>AMP</h1>
        <div className='logo' />
        {!isEditMode && (
          <div className='burger'>
            <div className='line1' />
            <div className='line2' />
            <div className='line3' />
          </div>
        )}
      </div>
      {!isEditMode && (
        <CheckIfLoggedIn>
          <div className='links'>
            <CheckAuthorization
              roles={[USERROLES.TRAINING_OFFICER]}
              shouldNavigate={false}
            >
              {parameters?.userId && parameters?.semesterId ? (
                <>
                  <Link
                    onClick={() => setIsMobileHeaderOpen(false)}
                    // eslint-disable-next-line max-len
                    to={`/users/${parameters?.userId}/semesters/${parameters?.semesterId}/weekly_reports`}
                  >
                    Wochenberichte
                  </Link>
                  <Link
                    onClick={() => setIsMobileHeaderOpen(false)}
                    // eslint-disable-next-line max-len
                    to={`/users/${parameters?.userId}/plan`}
                  >
                    Ausbildungsplan
                  </Link>
                </>
              ) : (
                <>
                  <Link onClick={() => setIsMobileHeaderOpen(false)} to='/'>
                    Alle Lernenden
                  </Link>
                  <Link
                    onClick={() => setIsMobileHeaderOpen(false)}
                    to='/multiplan'
                  >
                    Multiplan
                  </Link>
                </>
              )}
            </CheckAuthorization>
            <CheckAuthorization
              roles={[
                USERROLES.ADMIN,
                USERROLES.APPRENTICE_LEADER,
                USERROLES.INSTRUCTOR,
                USERROLES.HR
              ]}
              shouldNavigate={false}
            >
              {parameters?.userId && parameters?.semesterId ? (
                <>
                  <Link
                    onClick={() => setIsMobileHeaderOpen(false)}
                    // eslint-disable-next-line max-len
                    to={`/users/${parameters?.userId}/semesters/${parameters?.semesterId}/subjects`}
                  >
                    Schulnoten
                  </Link>
                  <Link
                    onClick={() => setIsMobileHeaderOpen(false)}
                    // eslint-disable-next-line max-len
                    to={`/users/${parameters?.userId}/semesters/${parameters?.semesterId}/assessment_points`}
                  >
                    Beurteilungen
                  </Link>
                  <Link
                    onClick={() => setIsMobileHeaderOpen(false)}
                    // eslint-disable-next-line max-len
                    to={`/users/${parameters?.userId}/semesters/${parameters?.semesterId}/weekly_reports`}
                  >
                    Wochenberichte
                  </Link>
                  <Link
                    onClick={() => setIsMobileHeaderOpen(false)}
                    // eslint-disable-next-line max-len
                    to={`/users/${parameters?.userId}/plan`}
                  >
                    Ausbildungsplan
                  </Link>
                </>
              ) : userParameters?.userId ? (
                <>
                  <Link onClick={() => setIsMobileHeaderOpen(false)} to='/'>
                    Alle Lernenden
                  </Link>
                  <Link
                    onClick={() => setIsMobileHeaderOpen(false)}
                    // eslint-disable-next-line max-len
                    to={`/users/${userParameters?.userId}/plan`}
                  >
                    Ausbildungsplan
                  </Link>
                </>
              ) : (
                <>
                  <Link onClick={() => setIsMobileHeaderOpen(false)} to='/'>
                    Alle Lernenden
                  </Link>
                  <Link
                    onClick={() => setIsMobileHeaderOpen(false)}
                    to='/multiplan'
                  >
                    Multiplan
                  </Link>
                </>
              )}
            </CheckAuthorization>
            <CheckAuthorization
              roles={[USERROLES.APPRENTICE]}
              shouldNavigate={false}
            >
              {parameters?.userId && parameters?.semesterId ? (
                <>
                  <Link
                    onClick={() => setIsMobileHeaderOpen(false)}
                    // eslint-disable-next-line max-len
                    to={`/users/${parameters?.userId}/semesters/${parameters?.semesterId}/subjects`}
                  >
                    Schulnoten
                  </Link>
                  <Link
                    onClick={() => setIsMobileHeaderOpen(false)}
                    // eslint-disable-next-line max-len
                    to={`/users/${parameters?.userId}/semesters/${parameters?.semesterId}/assessment`}
                  >
                    Beurteilungen
                  </Link>
                  <Link
                    onClick={() => setIsMobileHeaderOpen(false)}
                    // eslint-disable-next-line max-len
                    to={`/users/${parameters?.userId}/semesters/${parameters?.semesterId}/weekly_reports`}
                  >
                    Wochenberichte
                  </Link>
                  <Link
                    onClick={() => setIsMobileHeaderOpen(false)}
                    // eslint-disable-next-line max-len
                    to={`/users/${parameters?.userId}/plan`}
                  >
                    Ausbildungsplan
                  </Link>
                </>
              ) : (
                <Link
                  onClick={() => setIsMobileHeaderOpen(false)}
                  to={`/users/${getUserInfo()?.user?.id}/semesters`}
                >
                  Alle Semester
                </Link>
              )}
            </CheckAuthorization>
            <div className='dropdown-container'>
              <label
                tabIndex='0'
                onClick={() => setMenuOpen(!isMenuOpen)}
                onBlur={() => setTimeout(() => setMenuOpen(false), 150)}
                className={`${isMenuOpen ? 'header-dropdown-active' : ''}`}
              >
                {userInfo?.user?.firstname} {userInfo?.user?.lastname}
                <IoIosArrowDown
                  fill='#a8005c'
                  className={`dropdown-arrow${isMenuOpen ? ' open' : ''}`}
                />
              </label>
              <div className='dropdown-content'>
                <CheckAuthorization
                  roles={[USERROLES.ADMIN, USERROLES.APPRENTICE_LEADER]}
                  shouldNavigate={false}
                >
                  <Link
                    onClick={() => setIsMobileHeaderOpen(false)}
                    to='/users'
                  >
                    Benutzer anzeigen
                  </Link>
                </CheckAuthorization>
                <CheckAuthorization
                  roles={[
                    USERROLES.ADMIN,
                    USERROLES.APPRENTICE_LEADER,
                    USERROLES.INSTRUCTOR
                  ]}
                  shouldNavigate={false}
                >
                  <Link
                    onClick={() => setIsMobileHeaderOpen(false)}
                    to='/departments'
                  >
                    Abteilungen anzeigen
                  </Link>
                  <Link
                    onClick={() => setIsMobileHeaderOpen(false)}
                    to='/assessment_points/overview'
                  >
                    Beurteilungspunkt anzeigen
                  </Link>
                  <Link
                    onClick={() => setIsMobileHeaderOpen(false)}
                    to='/professions'
                  >
                    Berufe anzeigen
                  </Link>
                  <Link
                    onClick={() => setIsMobileHeaderOpen(false)}
                    to='/subjects'
                  >
                    Fächer anzeigen
                  </Link>
                </CheckAuthorization>
                <CheckAuthorization roles={USERROLES.ADMIN}>
                  <Link
                    onClick={() => setIsMobileHeaderOpen(false)}
                    to={'/templates'}
                  >
                    E-Mail-Templates bearbeiten
                  </Link>
                  <Link
                    onClick={() => setIsMobileHeaderOpen(false)}
                    to={'/email_settings'}
                  >
                    E-Mail-Einstellungen bearbeiten
                  </Link>
                </CheckAuthorization>
                <Link
                  onClick={() => setIsMobileHeaderOpen(false)}
                  to='/trigger_assign'
                >
                  E-Mail-Trigger verwalten
                </Link>
                <Link
                  onClick={() => setIsMobileHeaderOpen(false)}
                  to={`/users/${getUserInfo()?.user?.id}/edit`}
                >
                  Profil bearbeiten
                </Link>
                <Link to='/logout'>Logout</Link>
              </div>
            </div>
          </div>
        </CheckIfLoggedIn>
      )}
    </header>
  );
}
