/* eslint-disable react/jsx-key */
/* eslint-disable indent */
import { useEffect, useState } from 'react';
import { GoPencil } from 'react-icons/go';
import { IoIosAdd } from 'react-icons/io';
//Cheyenne Sägesser
import { MdLockReset } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import {
  ApiClient,
  CheckAuthorization,
  CheckIfLoggedIn,
  Input,
  LoadingIndicator,
  Row,
  Table
} from '../components';
import UserRoles from '../constants/UserRoles';

export function UsersOverview({ setBreadcrumbLinks }) {
  const { addToast } = useToasts();
  const [users, setUsers] = useState();
  const [inputTextName, setInputNameText] = useState('');
  const [inputTextProfession, setInputProfessionText] = useState('');
  const [inputTextRole, setInputRoleText] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    setBreadcrumbLinks([{ id: 1, label: 'Benutzer anzeigen', path: '/users' }]);
    ApiClient.get('users')
      .then(({ users }) => {
        setUsers(users.sort((a, b) => a.role.name.localeCompare(b.role.name)));
      })
      .catch(() =>
        addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
      );
  }, [addToast, setBreadcrumbLinks]);

  //Cheyenne Sägesser
  const onClickPasswordReset = userId => {
    ApiClient.patch(`users/${userId}/resetPassword`)
      .then(() => addToast('Email wurde gesendet', { appearance: 'success' }))
      .catch(() =>
        addToast('Etwas ist schief gelaufen!', { appearance: 'error' })
      );
  };

  return (
    <CheckIfLoggedIn>
      <CheckAuthorization
        roles={[UserRoles.ADMIN, UserRoles.APPRENTICE_LEADER]}
        shouldNavigate
        path='/404'
      >
        <div className='subjectOverview container'>
          <div className='content'>
            <h1 className='title'>
              Alle Benutzer
              <IoIosAdd
                onClick={() => navigate('/users/create')}
                className='title-icon'
              />
            </h1>
            <Table leftAlign>
              <Row
                key='thead'
                data={[
                  <Input
                    key='name'
                    type='text'
                    id='inputText'
                    name='inputText'
                    placeholder='Name'
                    value={inputTextName}
                    onChange={event => setInputNameText(event.target.value)}
                  />,
                  <Input
                    key='profession'
                    type='text'
                    id='profession'
                    name='profession'
                    placeholder='Beruf'
                    value={inputTextProfession}
                    onChange={event =>
                      setInputProfessionText(event.target.value)
                    }
                  />,
                  <Input
                    key='role'
                    type='text'
                    id='role'
                    name='role'
                    placeholder='Rolle'
                    value={inputTextRole}
                    onChange={event => setInputRoleText(event.target.value)}
                  />,
                  <></>,
                  <></>
                ]}
              />
              {users ? (
                Array.isArray(users.sort()) && users.length > 0 ? (
                  users
                    .filter(user =>
                      user.firstname
                        .toLowerCase()
                        .includes(inputTextName.toLowerCase())
                    )
                    .filter(user =>
                      user.profession
                        ? user.profession.name
                            .toLowerCase()
                            .includes(inputTextProfession.toLowerCase())
                        : !inputTextProfession
                        ? user
                        : ''
                    )
                    .filter(user =>
                      user.role.name
                        .toLowerCase()
                        .includes(inputTextRole.toLowerCase())
                    )
                    .map(user => (
                      <Row
                        key={user.id}
                        data={[
                          user.firstname + ' ' + user.lastname,
                          user.profession?.name,
                          user.role.name,
                          <GoPencil
                            onClick={() => navigate(`/users/${user.id}/edit`)}
                            style={{ cursor: 'pointer' }}
                          />,
                          //Cheyenne Sägesser
                          <MdLockReset
                            onClick={() => onClickPasswordReset(user.id)}
                          />
                        ]}
                      />
                    ))
                ) : (
                  <h2>Keine Berufe vorhanden!</h2>
                )
              ) : (
                <LoadingIndicator height={25} width={25} mode='darker' />
              )}
            </Table>
          </div>
        </div>
      </CheckAuthorization>
    </CheckIfLoggedIn>
  );
}
