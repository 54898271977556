//Cheyenne Sägesser
import '../styles/EmailTemplateOverview.css';

import { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';

import {
  ApiClient,
  Button,
  CheckAuthorization,
  CheckIfLoggedIn
} from '../components';
import UserRoles from '../constants/UserRoles';

export function EmailTemplateOverview({ setBreadcrumbLinks }) {
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [emailTemplates, setEmailTemplates] = useState();
  const [parameters, setParameters] = useState();
  const { addToast } = useToasts();

  useEffect(() => {
    setBreadcrumbLinks([
      { id: 1, label: 'Templates bearbeiten', path: '/templates' }
    ]);

    ApiClient.get('email/templates')
      .then(templates => setEmailTemplates(templates.templates))
      .catch(() =>
        addToast('Etwas ist schiefgelaufen', { appearance: 'error' })
      );
  }, [addToast, setBreadcrumbLinks, setEmailTemplates]);

  useEffect(() => {
    ApiClient.get(`email/templates/${selectedTemplate?.id}/parameters`)
      .then(parameters => setParameters(parameters))
      .catch(() =>
        addToast('Etwas ist schiefgelaufen', { appearance: 'error' })
      );
  }, [addToast, selectedTemplate?.id]);

  const onClick = () => {
    if (selectedTemplate) {
      const matchedParameters = checkParameters(
        selectedTemplate.body,
        parameters
      );
      if (matchedParameters) {
        const templateId = selectedTemplate.id
          ? selectedTemplate.id
          : addToast('Etwas ist schief gelaufen', { appearance: 'error' });
        const body = {
          subject: selectedTemplate.subject,
          body: selectedTemplate.body
        };
        ApiClient.patch(`email/templates/${templateId}`, body)
          .then(() => {
            updateTemplateInList();
            addToast('Das Template wurde aktualisiert', {
              appearance: 'success'
            });
          })
          .catch(() =>
            addToast('Etwas ist schiefgelaufen', { appearance: 'error' })
          );
      } else {
        addToast('Bitte geben Sie gültige Parameter ein');
      }
    } else {
      addToast('Ein Fehler ist aufgetreten. Bitte versuchen Sie es nochmals', {
        appearance: 'error'
      });
    }
  };
  const updateTemplateInList = () => {
    setEmailTemplates(previousTemplates =>
      previousTemplates.map(emailTemplate =>
        emailTemplate.id === selectedTemplate.id
          ? { ...emailTemplate, ...selectedTemplate }
          : emailTemplate
      )
    );
  };

  const handleTextareaChange = event => {
    setSelectedTemplate(previousTemplate => ({
      ...previousTemplate,
      body: event.target.value
    }));
  };

  function checkParameters(text, parameters) {
    //https://www.30secondsofcode.org/js/s/common-regexp-cheatsheet/
    const regex = /{{(.*?)}}/g;
    const extractedParameters = text.match(regex);
    if (extractedParameters) {
      for (const parameter of extractedParameters) {
        let parameterExists = false;
        for (const allowedParameter of parameters) {
          if (allowedParameter.code === parameter) {
            parameterExists = true;
            break;
          }
        }
        if (!parameterExists) {
          return false;
        }
      }
      return true;
    } else {
      return false;
    }
  }

  return (
    <CheckIfLoggedIn>
      <CheckAuthorization roles={[UserRoles.ADMIN]} shouldNavigate path='/403'>
        <div className='email container'>
          <div className='email-content'>
            <div className='email-navbar'>
              <h2 className='email-title'>Email Templates</h2>
              <ul>
                {emailTemplates ? (
                  emailTemplates.map(item => (
                    <li key={item.id} onClick={() => setSelectedTemplate(item)}>
                      {item.subject}
                    </li>
                  ))
                ) : (
                  <h3>No Templates found</h3>
                )}
              </ul>
            </div>
            <div className='border'></div>
            {selectedTemplate ? (
              <>
                <div className='template-details'>
                  <h2 className='template-title'>Template Details</h2>
                  <div>
                    <h3>{selectedTemplate?.name}</h3>
                    <div className='textarea-container'>
                      <textarea
                        value={selectedTemplate?.body}
                        onChange={handleTextareaChange}
                      />
                      {selectedTemplate ? (
                        <div className='button-space'>
                          <Button text='Speichern' onClick={onClick} />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>{' '}
                  </div>
                </div>
                <div className='parameter-box'>
                  <h2 className='template-title'>Parameter</h2>
                  {parameters?.map(parameter => (
                    <p key={parameter.id}>{parameter.code}</p>
                  ))}
                </div>
              </>
            ) : (
              <div className='template-details'>
                <h2>Template</h2>
                <p>Klicken Sie das Email-Template an, um es zu bearbeiten.</p>
              </div>
            )}
          </div>
        </div>
      </CheckAuthorization>
    </CheckIfLoggedIn>
  );
}
